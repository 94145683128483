import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState, handleClick } from "react";
// import logo from "/src/logo192.png";

const Login = () => {
  let loginToken = "";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const allowedUser = "Shubham";
  const allowedPassword = "Shubham@123";
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    //navigate

    if (username === allowedUser && password === allowedPassword) {
      axios
        .post("https://vidani.smart-iot.in/api/super-admin/login", {
          // username: "Shubham",
          // password: "Shubham@123",
          // remember: true,
          // domain: "vidani",
          username: username,
          password: password,
          remember: true,
          domain: "vidani",
        })
        .then(function (response) {
          console.log(response.data.message);
          loginToken = response.data.data;
          loginToken = localStorage.setItem(
            "loginToken",
            JSON.stringify(loginToken)
          );
          console.log(localStorage.getItem("loginToken"));

          navigate({
            pathname: "/Container.js",
            state: { loginToken: loginToken },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Invalid username or password");
    }

    //navigate
  };

  return (
    <div className="bg-gray-900 h-screen w-screen flex flex-wrap items-center justify-center relative overflow-hidden">
      <form
        className="flex items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="items-center bg-gray-100 shadow-blue-500/50 shadow-lg w-60 p-6 rounded-lg  sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3">
          <div className="flex items-center justify-center mb-4">
            <img src="/images/icon.png" alt="logo" className="h-32" />
          </div>
          <label className="text-gray-900 font-mono text-lg">Username</label>
          <input
            className="w-full py-2 bg-gray-100 text-gray-500 px-1 outline-none mb-5 rounded-lg shadow-blue-500/50 shadow-lg"
            type="text"
            placeholder="Enter your Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          ></input>
          <label className="text-gray-900 font-mono text-lg">Password</label>
          <input
            className="w-full py-2 bg-gray-100 text-black px-1 outline-none mb-4 rounded-lg shadow-blue-500/50 shadow-lg"
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          ></input>
          {/* <Link to="/Container.js"> */}
          <button
            onClick={navigate}
            type="submit"
            className="bg-blue-500 shadow-blue-500/50 shadow-lg w-full text-white py-2 rounded-lg hover:bg-orange-600 transition-colors font-mono text-lg mb-3"
          >
            Login
          </button>
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
