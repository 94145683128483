import React from "react";

const Activetime = () => {
  const tableData = [
    {
      id: 1,
      date: "February 1st, 2023",
      time: "8:30am - 10:00am",
      activity: "Morning exercise",
    },
    {
      id: 2,
      date: "February 1st, 2023",
      time: "10:30am - 12:00pm",
      activity: "Work meeting",
    },
    {
      id: 3,
      date: "February 1st, 2023",
      time: "1:00pm - 2:00pm",
      activity: "Lunch",
    },
    {
      id: 4,
      date: "February 1st, 2023",
      time: "2:30pm - 4:00pm",
      activity: "Afternoon exercise",
    },
  ];

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">ID</th>
            <th className="py-3 px-6 text-left">Date</th>
            <th className="py-3 px-6 text-left">Time</th>
            <th className="py-3 px-6 text-left">Activity</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {tableData.map((data) => (
            <tr
              key={data.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="py-3 px-6 text-left whitespace-nowrap">
                {data.id}
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">
                {data.date}
              </td>
              <td className="py-3 px-6 text-left whitespace-nowrap">
                {data.time}
              </td>
              <td className="py-3 px-6 text-left">{data.activity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Activetime;
