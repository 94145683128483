import React from "react";
import Barchart from "./Barchart";
import { Link } from "react-router-dom";

const GraphsSh2 = () => {
  const power = JSON.parse(localStorage.getItem("power"));
  //console.log(power);
  const time = JSON.parse(localStorage.getItem("time"));
  //console.log(time);

  let sh2hr1pow = [];
  let sh2hr2pow = [];
  let sh2hr3pow = [];
  let sh2hr4pow = [];
  let sh2hr5pow = [];
  let sh2hr6pow = [];
  let sh2hr7pow = [];
  let sh2hr8pow = [];
  let sh2hr9pow = [];
  let sh2hr10pow = [];
  let sh2hr11pow = [];
  let sh2hr12pow = [];

  let sh2hr1time = [];
  let sh2hr2time = [];
  let sh2hr3time = [];
  let sh2hr4time = [];
  let sh2hr5time = [];
  let sh2hr6time = [];
  let sh2hr7time = [];
  let sh2hr8time = [];
  let sh2hr9time = [];
  let sh2hr10time = [];
  let sh2hr11time = [];
  let sh2hr12time = [];

  for (let i = 0; i < power.length; i++) {
    //shift2 8am-9 am
    if (time[i] >= "08:00:00" && time[i] < "09:00:00") {
      sh2hr1time.push(time[i]);
      sh2hr1pow.push(power[i]);
    }

    //shift2 9am-10am
    if (time[i] >= "09:00:00" && time[i] < "10:00:00") {
      sh2hr2time.push(time[i]);
      sh2hr2pow.push(power[i]);
    }

    //shift2 10-11am
    if (time[i] >= "10:00:00" && time[i] < "11:00:00") {
      sh2hr3time.push(time[i]);
      sh2hr3pow.push(power[i]);
    }

    //shift2 11am-12noon
    if (time[i] >= "11:00:00" && time[i] < "12:00:00") {
      sh2hr4time.push(time[i]);
      sh2hr4pow.push(power[i]);
    }

    //shift2 12noon-13pm
    if (time[i] >= "12:00:00" && time[i] < "13:00:00") {
      sh2hr5time.push(time[i]);
      sh2hr5pow.push(power[i]);
    }

    //shift2 13-14pm
    if (time[i] >= "13:00:00" && time[i] < "14:00:00") {
      sh2hr6time.push(time[i]);
      sh2hr6pow.push(power[i]);
    }

    //shift2 14-15pm
    if (time[i] >= "14:00:00" && time[i] < "15:00:00") {
      sh2hr7time.push(time[i]);
      sh2hr7pow.push(power[i]);
    }

    //shift2 15-16pm
    if (time[i] >= "15:00:00" && time[i] < "16:00:00") {
      sh2hr8time.push(time[i]);
      sh2hr8pow.push(power[i]);
    }

    //shift2 16-17pm
    if (time[i] >= "16:00:00" && time[i] < "17:00:00") {
      sh2hr9time.push(time[i]);
      sh2hr9pow.push(power[i]);
    }

    //shift2 17-18pm
    if (time[i] >= "17:00:00" && time[i] < "18:00:00") {
      sh2hr10time.push(time[i]);
      sh2hr10pow.push(power[i]);
    }

    //shift2 18-19pm
    if (time[i] >= "18:00:00" && time[i] < "19:00:00") {
      sh2hr11time.push(time[i]);
      sh2hr11pow.push(power[i]);
    }

    //shift2 19-20pm
    if (time[i] >= "19:00:00" && time[i] < "20:00:00") {
      sh2hr12time.push(time[i]);
      sh2hr12pow.push(power[i]);
    }
  }

  return (
    <div className="mx-3">
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            8:00-9:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr1pow} tfinal={sh2hr1time} divider={4} />
          {/* <Graphs graphData={graphData} /> */}
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            9:00-10:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr2pow} tfinal={sh2hr2time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            10:00-11:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr3pow} tfinal={sh2hr3time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            11:00-12:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr4pow} tfinal={sh2hr4time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            12:00-13:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr5pow} tfinal={sh2hr5time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            13:00-14:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr6pow} tfinal={sh2hr6time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            14:00-15:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr7pow} tfinal={sh2hr7time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            15:00-16:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr8pow} tfinal={sh2hr8time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            16:00-17:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr9pow} tfinal={sh2hr9time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            17:00-18:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr10pow} tfinal={sh2hr10time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            18:00-19:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr11pow} tfinal={sh2hr11time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            19:00-20:00 SHIFT-II
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh2hr12pow} tfinal={sh2hr12time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            8:00-20:00 SHIFT-II Clustured Data
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={power} tfinal={time} divider={60} />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        <Link to="/src/shift1/Sh2Container.js">
          <button className="bg-blue-400 rounded-lg w-40 p-3 m-2">
            Go Back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GraphsSh2;
