import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Graphs from "./shift1/Graphs";
import Container from "./shift1/Container";
import Activetime from "./Activetime";
// import Token from "./shift1/Token";
import { pfinal, tfinal } from "./shift1/Container";
import Sh2Container from "./shift1/Sh2Container";
import GraphsSh2 from "./shift1/GraphsSh2";
import Login from "./shift1/Login";

function App() {
  // let [tokenArr, setTokenArr] = useState([]);
  //console.log(pfinal);
  // tokenArr = JSON.parse(localStorage.getItem("tokenArr"));
  // console.log(tokenArr);
  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route exact path="/" element={<Token />} /> */}
        <Route exact path="/" element={<Login />} />
        {/* <Route exact path="/" element={<Container />} /> */}
        <Route exact path="/Container.js" element={<Container />} />

        <Route exact path="/Graphs" element={<Graphs />} />

        <Route exact path="/GraphsSh2" element={<GraphsSh2 />} />

        <Route
          exact
          path="/src/shift1/Sh2Container.js"
          element={<Sh2Container />}
        />

        <Route exact path="/Activetime" element={<Activetime />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
